import { FC } from 'react';
import { Row, Col, Divider, Layout } from 'antd';

import './App.css';
import 'antd/dist/antd.css';
import './index.css';

import { Content, Footer, Header } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';

import { SearchPanel } from './components/search/searchPanel';
import { MixturePanel } from './components/mixture/mixture';
import { SignatureShapesPanel } from './components/aggregated/signatureShapes';

const App: FC = () => {
  return (
    <Layout>
      <Header>
        <Row justify='space-between' gutter={[20,20]}>
          <Col>
            <Row justify='center'>
              <Col>
                <img style={{width: '100px'}} src="https://aryballe.com/wp-content/uploads/2019/08/logo-aryballe-odor-analytics.png"></img>
              </Col>
            </Row>
            {/* <Row>
            <Col>
                <h1 style={{margin: 0, color: 'white'}}>Olfactive Library</h1>
              </Col>
            </Row> */}
          </Col>
          <Col>
            <Row justify={'space-between'} gutter={[20, 20]}>
            <Col>
                <a style={{color: 'white'}} href="#search">Home</a>
              </Col>
              <Col>
                <a style={{color: 'white'}} href="#mixture">Mixture Model</a>
              </Col>
              <Col>
                <a style={{color: 'white'}} href="#shapes">Foundations</a>
              </Col>
            </Row>
          </Col>
        </Row>

      </Header>
      <Layout >
        <Content
          style={{minHeight: '100vh'}}
        >
          <div id='search' className='app-canvas-section'>
            <Row justify='center' align="top" style={{minHeight: '100vh', marginTop: '64px'}} >
              <Col xs={24} md={20}>
                <Row justify='center'><Title>Explore Olfactive Space</Title></Row>
                <Row justify='center'><Title level={3}>Select how you would like to query the space</Title></Row>
                <Row justify='center'><SearchPanel/></Row>
              </Col>
            </Row>
          </div>
        </Content>
        <Content>
        <div className='app-canvas-section'>
          <Row justify='center'>
            <Col xs={24} md={20}>
              <MixturePanel/>
            </Col>
          </Row>
        </div>
        </Content>
        <Content>
          <div className='app-canvas-section'>
            <Row justify='center'>
              <Col xs={24} md={22}>
                <SignatureShapesPanel/>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
      <Footer>
        {/* <Divider>Statistics</Divider>
        <Row justify="center" gutter={[20,20]}>
          <Col style={{textAlign: 'center'}}>
            <p>Total number of compounds: <b>7453</b></p>
            <p>Including compounds with CAS#: <b>5736</b></p>
            <p>Including pure chemicals: <b>3962</b></p>
            <p>Including measured by Aryballe's NeOse Advance: <b>104</b></p>
          </Col>
        </Row> */}
        <Row justify="center" gutter={[20,20]}>
          <Col style={{textAlign: 'center'}}>
            <div style={{color: 'black'}}><a target="_blank" href="https://aryballe.com">© 2022 Aryballe Technologies</a></div>
          </Col>
        </Row>
      </Footer>
    </Layout>
  )
};

export default App;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Compound } from '../../types';
// import { RootState, AppThunk } from '../../app/store';


export interface MixtureCompounds {
  compoundA: Compound | null
  compoundB: Compound | null
}

const initialState: MixtureCompounds = {
    compoundA: null,
    compoundB: null
};

export const mixerSlice = createSlice({
  name: 'mixer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setA: (state, action: PayloadAction<Compound>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log('Reducer SetA', action.payload)
      state.compoundA = action.payload;
    },
    setB: (state, action: PayloadAction<Compound>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        console.log('Reducer SetB', action.payload)
        state.compoundB = action.payload;
    }
  },
});

export const { setA, setB } = mixerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCompoundA = (state: RootState) => state.mixer.compoundA;
export const selectCompoundB = (state: RootState) => state.mixer.compoundB;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default mixerSlice.reducer;

import { Col, Divider, Pagination, Row, Select, Skeleton } from "antd";
import { FC, memo, useEffect, useState } from "react";
import { chemicalFamiliesColorMap, odorStrengthColorMap, odorTypeColorMap } from "../../../colormap/colormap";
import { ChemicalFamily, Compound, KeyValuePair } from "../../../types";
import { CommonPropertiesBarPlot } from "../../charts/antv";
import Title from 'antd/lib/typography/Title';
import { SearchPanelCompoundCard } from "../../compound/compoundCard";
import { getAPIEndpoint } from "../../../endpoint";
import { capitalizeFirstLetter } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import { ChemicalFamiliesCombinedInsight } from "../signature/signature";
import { PaginatedCompoundCardSet } from "../common";

export const SearchPanelSelectChemicalFamily: FC = () => {
    const [chemicalFamilies, setCompounds] = useState<ChemicalFamily[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();
    var searchFamiliesStr = searchParams.get('searchFamilies')

    useEffect(() => {
      fetch(`${getAPIEndpoint()}/chemical_families`)
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        } else {
          throw new Error()
        }
      })
        .then(data => setCompounds(data))
    }, [])

    var searchFamilies: string[] = []
    var effectiveChemicalFamilies: ChemicalFamily[] = []

    if (searchFamiliesStr === "") {
      searchFamiliesStr = null
    } 
    if (searchFamiliesStr !== null) {
      searchFamilies = searchFamiliesStr.split(',')
      effectiveChemicalFamilies = chemicalFamilies.filter(e => searchFamilies.includes(e.Name))

      // console.log(searchFamiliesStr, effectiveChemicalFamilies)

      // if (effectiveChemicalFamilies.map(cf => cf.Name).join(",") === searchFamiliesStr) {
      //   console.log("Avoiding search by chemical family select update!")
      //   return null
      // }
    }
  
    const handleChange = (value: string[], option: KeyValuePair | KeyValuePair[]) => {
      searchParams.set("searchFamilies", value.join(','))
      setSearchParams(searchParams)
    }

    return (
      <Col>
        <Row justify='center'>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%'}}
            placeholder="Search for chemistry.."
            onChange={handleChange}
            size='large'
            value={searchFamilies.length ? searchFamilies : undefined}
            defaultValue={searchFamilies.length ? searchFamilies : undefined}
            autoFocus={true}
          >
            {chemicalFamilies.map(cf => {
              return <Select.Option key={cf.ID} value={cf.Name}>{cf.Name}</Select.Option>
            })}
          </Select>
        </Row>
        <Row justify="space-between" gutter={[20,20]}>
            <Col>Try:</Col>
            <Col><a onClick={(e) => {searchParams.set("searchFamilies", "Alcohol"); setSearchParams(searchParams)}}>Alcohols</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchFamilies", "Ketone"); setSearchParams(searchParams)}}>Ketones</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchFamilies", "Aromatic,Alcohol"); setSearchParams(searchParams)}}>Aromatic alcohols</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchFamilies", "Sulfurous"); setSearchParams(searchParams)}}>Sulfurous</a></Col>
        </Row>
        <Row>
            <SearchPanelChemicalFamilyResult
              chemicalFamilies={effectiveChemicalFamilies}
              chemicalFamilyNames={searchFamilies}
            />
        </Row>
      </Col>
    )
}
  
export const SearchPanelChemicalFamilyResult: FC<{chemicalFamilies: ChemicalFamily[], chemicalFamilyNames: string[]}> = (props: {chemicalFamilies: ChemicalFamily[], chemicalFamilyNames: string[]}) => {
    const [compounds, setCompounds] = useState<Compound[]>([])
    const { chemicalFamilies, chemicalFamilyNames } = props;
    const [ isLoading, setIsLoading ] = useState<boolean>(true)

    const chemicalFamiliesIDsStr = chemicalFamilies.map(cf => cf.ID).join(',')

    useEffect(() => {
      setIsLoading(true)
      fetch(`${getAPIEndpoint()}/compounds/by/chemical_families?q=${chemicalFamilies.map(cf => cf.ID).join(",")}`)
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        } else {
          throw new Error()
        }
      })
        .then(data => {
          setCompounds(data)
          setIsLoading(false)
        })
    }, [chemicalFamiliesIDsStr])
  
    const flattenUniqueMap = (uniqueMap: Record<string, number>) => {
      let uniqueFlat = Object.entries(uniqueMap).map(([key, value]) => ({key: key, value: value}))
      uniqueFlat.sort((a, b) => b.value - a.value)
      return uniqueFlat
    }
  
    const uniqueSmells: Record<string, number> = {};
    compounds.forEach(c => {
      c.Smells.forEach(s => {
        uniqueSmells[s.Name] = 0
      })
    })
    compounds.forEach(c => {
      c.Smells.forEach(s => {
        uniqueSmells[s.Name] += s.Level != null && s.Level !== 0 ? 1/s.Level : 1
      })
    })
    var uniqueSmellsFlat = flattenUniqueMap(uniqueSmells)
    uniqueSmellsFlat = uniqueSmellsFlat.slice(0, 5)
  
    const uniqueOdorTypes: Record<string, number> = {};
    compounds.forEach(c => {
      uniqueOdorTypes[c.OdorType.Name] = 0
    })
    compounds.forEach(c => {
      uniqueOdorTypes[c.OdorType.Name] += 1
    })
    var uniqueOdorTypesFlat = flattenUniqueMap(uniqueOdorTypes)
    uniqueOdorTypesFlat = uniqueOdorTypesFlat.slice(0, 5)
  
    const uniqueOdorStrengths: Record<string, number> = {};
    compounds.forEach(c => {
      uniqueOdorStrengths[c.OdorStrength.Name] = 0
    })
    compounds.forEach(c => {
      uniqueOdorStrengths[c.OdorStrength.Name] += 1
    })
    var uniqueOdorStrengthsFlat = flattenUniqueMap(uniqueOdorStrengths)
    uniqueOdorStrengthsFlat = uniqueOdorStrengthsFlat.slice(0, 5)
  
    if (compounds.length === 0) {
      return null
    }
    const uniqueFlatNames = ['Most commonly perceived as', 'Most common odor types', 'Odor strengths']

    if (compounds === undefined) {
      return null
    }

    return (
      <div className='search-panel-result'>
        <Divider>
          <Title level={3}>{chemicalFamilyNames.map(e => capitalizeFirstLetter(e)).join(' & ')}</Title>
        </Divider>
        <Row justify='center' gutter={[20, 20]} >
          <Col span={24}>
          <ChemicalFamiliesCombinedInsight
                chemicalFamilies={chemicalFamilies}
                weights={chemicalFamilies.map(e => 1)}
            />
          </Col>
        </Row>
          <Divider>VOCs</Divider>
          {isLoading ? 
            <Row justify='center' gutter={[20,20]}>
              <Col span={8}><Skeleton active={true}/></Col>
              <Col span={8}><Skeleton active={true}/></Col>
              <Col span={8}><Skeleton active={true}/></Col>
            </Row>
          :
            <Row justify='center' gutter={[20, 20]}  >
              <PaginatedCompoundCardSet compounds={compounds}/>
            </Row>
          }
      </div>
    )
  }
import { Col, Divider, Row, Select, Skeleton } from "antd";
import { FC, useEffect, useState } from "react";
import { chemicalFamiliesColorMap, odorStrengthColorMap, odorTypeColorMap } from "../../../colormap/colormap";
import { Compound, KeyValuePair, Smell } from "../../../types";
import { CommonPropertiesBarPlot } from "../../charts/antv";
import Title from 'antd/lib/typography/Title';
import { SearchPanelCompoundCard } from "../../compound/compoundCard";
import { getAPIEndpoint } from "../../../endpoint";
import { capitalizeFirstLetter } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import { PaginatedCompoundCardSet } from "../common";

export const SearchPanelSelectOdor: FC = () => {
    const [smells, setSmells] = useState<Smell[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();
    var searchOdorsStr = searchParams.get('searchOdors')
    var searchOdors: string[] = []
    if (searchOdorsStr === "") {
      searchOdorsStr = null
    } 
    if (searchOdorsStr !== null) {
      searchOdors = searchOdorsStr.split(',')
    }

    const update = () => {
      fetch(`${getAPIEndpoint()}/smells`)
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        } else {
          throw new Error()
        }
      })
        .then(data => setSmells(data))
    }
    useEffect(() => update(), [])
  
    const handleChange = (value: string[], option: KeyValuePair | KeyValuePair[]) => {
      searchParams.set("searchOdors", value.join(','))
      setSearchParams(searchParams)
    }
   
    return (
      <Col span={24}>
        <Row justify='center'>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%'}}
            placeholder="Search odor percepts.."
            onChange={handleChange}
            size='large'
            value={searchOdors.length ? searchOdors : undefined}
            defaultValue={searchOdors.length ? searchOdors : undefined}
            autoFocus={true}
          >
            {smells.map(smell => {
              return <Select.Option key={smell.ID} value={smell.Name}>{smell.Name}</Select.Option>
            })}
          </Select>
        </Row>
        <Row justify="space-between" gutter={[20,20]}>
            <Col>Try:</Col>
            <Col><a onClick={(e) => {searchParams.set("searchOdors", "pear"); setSearchParams(searchParams)}}>pear</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchOdors", "herbal"); setSearchParams(searchParams)}}>herbal</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchOdors", "spearmint"); setSearchParams(searchParams)}}>spearmint</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchOdors", "sulfurous"); setSearchParams(searchParams)}}>sulfurous</a></Col>
        </Row>
        <Row>
          <SearchPanelOdorResult
            smellIDs={smells.filter(e => searchOdors.includes(e.Name)).map(e => e.ID)}
            smellNames={searchOdors}
          />
        </Row>
      </Col>
    )
  }
  
const SearchPanelOdorResult: FC<{smellIDs: number[], smellNames: string[]}> = (props: {smellIDs: number[], smellNames: string[]}) => {
  const { smellIDs, smellNames } = props;
  const [compounds, setCompounds] = useState<Compound[]>([])

  const [ searchPrams, setSearchParams ] = useSearchParams()

  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const smellIDsStr = smellIDs.map(sid => sid).join(',')

  var openedCompoundID: number = -1
  var openedCompoundIDStr = searchPrams.get('compoundID')
  if (openedCompoundIDStr === "") {
    openedCompoundIDStr = null
  }
  if (openedCompoundIDStr !== null) {
    openedCompoundID = parseInt(openedCompoundIDStr)
  }

  useEffect(() => {
    setIsLoading(true)
    fetch(`${getAPIEndpoint()}/compounds/by/smells?q=${smellIDs.join(",")}`)
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        } else {
          throw new Error()
        }
      })
      .then(data => {
        setCompounds(data)
        setIsLoading(false)
      })
  }, [smellIDsStr])

  const flattenUniqueMap = (uniqueMap: Record<string, number>) => {
    let uniqueChemicalFamiliesFlat = Object.entries(uniqueMap).map(([key, value]) => ({key: key, value: value}))
    uniqueChemicalFamiliesFlat.sort((a, b) => b.value - a.value)
    return uniqueChemicalFamiliesFlat
  }

  const uniqueChemicalFamilies: Record<string, number> = {};
  compounds.forEach(c => {
    c.ChemicalFamilies.forEach(cf => {
      uniqueChemicalFamilies[cf.Name] = 0
    })
  })
  compounds.forEach(c => {
    c.ChemicalFamilies.forEach(cf => {
      uniqueChemicalFamilies[cf.Name] += 1
    })
  })
  const uniqueChemicalFamiliesFlat = flattenUniqueMap(uniqueChemicalFamilies)

  const uniqueOdorTypes: Record<string, number> = {};
  compounds.forEach(c => {
    uniqueOdorTypes[c.OdorType.Name] = 0
  })
  compounds.forEach(c => {
    uniqueOdorTypes[c.OdorType.Name] += 1
  })
  const uniqueOdorTypesFlat = flattenUniqueMap(uniqueOdorTypes)

  const uniqueOdorStrengths: Record<string, number> = {};
  compounds.forEach(c => {
    uniqueOdorStrengths[c.OdorStrength.Name] = 0
  })
  compounds.forEach(c => {
    uniqueOdorStrengths[c.OdorStrength.Name] += 1
  })
  const uniqueOdorStrengthsFlat = flattenUniqueMap(uniqueOdorStrengths)

  if (compounds.length === 0) {
    return null
  }
  const uniqueFlatNames = [
    'Most common chemistries​',
    'Most common percepts',
    // 'Comon odor strengths'
  ]
  return (
    <div className='search-panel-result'>
      <Divider>
        <Title level={3}>{smellNames.map(e => capitalizeFirstLetter(e)).join(' & ')}</Title>
      </Divider>
      <Row justify='center' gutter={[20, 20]}>
        {[
          uniqueChemicalFamiliesFlat,
          uniqueOdorTypesFlat,
          // uniqueOdorStrengthsFlat
        ].map((uniqueFlat, i) => {
          let colormap: Record<string, string> = {}
          switch (i) {
            case 0:
              colormap = chemicalFamiliesColorMap
              break
            case 1:
              colormap = odorTypeColorMap
              break
            case 2:
              colormap = odorStrengthColorMap
              break
          }
          return <Col>
            <Divider>{uniqueFlatNames[i]}</Divider>
            <div style={{minWidth: '15vw', height: '200px'}}>
                <CommonPropertiesBarPlot properties={uniqueFlat} colormap={colormap}/>
            </div>
        </Col>
        })}
      </Row>
      <Divider>VOCs</Divider>
      {
        isLoading ? 
          <Row justify='center' gutter={[20,20]}>
            <Col span={8}><Skeleton active={true}/></Col>
            <Col span={8}><Skeleton active={true}/></Col>
            <Col span={8}><Skeleton active={true}/></Col>
          </Row>
        :
          <Row justify='center' gutter={[20, 20]}>
            <PaginatedCompoundCardSet compounds={compounds}/>
          </Row>
      }
    </div>
  )
}
import { Bar, Gauge } from "@ant-design/plots"
import { FC } from "react"

export const CommonPropertiesBarPlot: FC<{properties: {key: string, value: number}[], colormap: Record<string,string>}> = (props: {properties: {key: string, value: number}[], colormap: Record<string,string>}) => {
    const { properties, colormap } = props;
    return <Bar
    data={properties}
    xField='value'
    yField='key'
    seriesField='key'
    color={({ key }) => {
      if (key === undefined) {
        return ''
      } else {
        return colormap[key]
      }
    }}
    legend={false}
    autoFit={true}
  />
}

export const GaugePlot: FC<{percent: number}> = (props: {percent: number}) => {
    const { percent } = props
    return <Gauge
    percent={percent}
    type='meter'
    innerRadius={0.75}
    range={{
      ticks: [0, 1/3, 2/3, 1],
      color: ['#30BF78', '#FAAD14', '#F4664A'].map((c, i) => {
        if (percent > i/3) {
          return c
        } else {
          return "white"
        }
      })
    }}
    autoFit={true}
    width={200}
    height={200}
 />
}
import { Col, Divider, Row, Select, Skeleton } from "antd";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAPIEndpoint } from "../../../endpoint";
import { Compound } from "../../../types";
import { SearchPanelCompoundCard } from "../../compound/compoundCard";

export const SearchPanelSelectVOC: FC = () => {
    const [compounds, setVocs] = useState<Compound[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [searchParams, setSearchParams] = useSearchParams();

    var searchOdorsStr = searchParams.get('searchVoc')
    var searchVoc: string[] = []
    if (searchOdorsStr === "") {
      searchOdorsStr = null
    } 
    if (searchOdorsStr !== null) {
      searchVoc = searchOdorsStr.split(';')
    }
 
    useEffect(() => {
      setIsLoading(true)
      fetch(`${getAPIEndpoint()}/compounds`)
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        } else {
          throw new Error()
        }
      })
        .then(data => {
          setVocs(data)
          setIsLoading(false)
        })
    }, [])
   
    const synonymToCompoundMap: Record<string, Compound> = {}
    compounds.forEach(c => {
      synonymToCompoundMap[c.CommonName] = c
      // c.Synonyms.forEach(s => {
      //   synonymToCompoundMap[s.Name] = c
      // })
    })

    const handleChange = (value: string[]) => {
      searchParams.set("searchVoc", value.join(';'))
      setSearchParams(searchParams)
    }

    // console.log(searchVoc)

    return (
      <Col span={24}>
        <Row justify='center'>
          <Select
            mode="multiple"
            allowClear
            showSearch
            style={{ width: '100%'}}
            placeholder="Search VOCs by name or by CAS.."
            onChange={handleChange}
            size='large'
            value={searchVoc.length ? searchVoc : undefined}
            defaultValue={searchVoc ? searchVoc : undefined}
            autoFocus={true}
          >
            {compounds.map(voc => {
              // return <Select.OptGroup value={voc.CAS} label={`${voc.CommonName}: ${voc.CAS}`}>
              //   {voc.Synonyms.map(synonym => {
              //     return <Select.Option key={voc.CAS+synonym.Name} value={synonym.Name}>{synonym.Name}</Select.Option>
              //   })
              // }
              // </Select.OptGroup>
              return <Select.Option value={`${voc.CommonName}:${voc.CAS}`}>{`${voc.CommonName}: `}<b>{`${voc.CAS}`}</b></Select.Option>
            })}
          </Select>
        </Row>
        <Row justify="space-between" gutter={[20,20]}>
            <Col>Try:</Col>
            <Col><a onClick={(e) => {searchParams.set("searchVoc", "(Z)-3-hexen-1-ol"); setSearchParams(searchParams)}}>cis-3-hexenol</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchVoc", "phenethyl alcohol"); setSearchParams(searchParams)}}>PEA</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchVoc", "amyl mercaptan"); setSearchParams(searchParams)}}>amyl mercaptan</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchVoc", "ethyl cinnamate"); setSearchParams(searchParams)}}>103-36-6</a></Col>
            <Col><a onClick={(e) => {searchParams.set("searchVoc", "ethyl isobutyrate"); setSearchParams(searchParams)}}>ethyl isobutyrate</a></Col>
        </Row>
        <Row justify='center'>
          <Divider></Divider>
          {isLoading ? 
            <Skeleton active={true}></Skeleton>
          :
            <SearchPanelVocResult
              compounds={compounds.filter(c => {
                // return searchVoc.includes(c.CommonName)
                for (let i=0;i<searchVoc.length; i++) {
                  let svoc = searchVoc[i]
                  let cn = svoc.split(':')[0]
                  if (cn === c.CommonName) {
                    return true
                  }
                }
                return false
              })}
            />
        }
        </Row>
      </Col>
    )        
}

const SearchPanelVocResultCompound: FC<{compound: Compound}> = (props: {compound: Compound}) => {
  const { compound } = props;
  const [ actualCompound, setActualCompound ] = useState<Compound | null>(null)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  useEffect(() => {
    setIsLoading(true)
    fetch(`${getAPIEndpoint()}/compound?id=${compound.ID}`)
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        } else {
          throw new Error()
        }
      })
      .then(data => {
        setActualCompound(data)
        setIsLoading(false)
      })
  }, [compound.ID])

  if (actualCompound === null) {
    return null
  } 

  return isLoading ? <Skeleton/> : <SearchPanelCompoundCard compound={actualCompound}/>
}

const SearchPanelVocResult: FC<{compounds: Compound[]}> = (props: {compounds: Compound[]}) => {
    const { compounds } = props;
    const [ searchParams, setSearchParams ] = useSearchParams()
    if (compounds.length === 0) {
        return null
    }

    var openedCompoundID: number = -1
    var openedCompoundIDStr = searchParams.get('compoundID')
    if (openedCompoundIDStr === "") {
        openedCompoundIDStr = null
    }
    if (openedCompoundIDStr !== null) {
        openedCompoundID = parseInt(openedCompoundIDStr)
    }
    
    return (
        <Row justify='center' gutter={[20, 20]}>
            {compounds.map(compound => 
              <SearchPanelVocResultCompound compound={compound}/>
              
            )}
      </Row>
    )
    }
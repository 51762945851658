import { Button, Card, Col, Modal, notification, Row, Skeleton, Tag, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { odorTypeColorMap } from "../../colormap/colormap";
import { Compound } from "../../types";
import { GaugePlot } from "../charts/antv";
import { CompoundPanel } from "./compound";

import {
    setA,
    setB,
  } from '../../features/mixer/mixerSlice';
  
import { useAppDispatch } from '../../app/hooks';
import { ModeledSignaturePlot, SignaturesPlot } from "../charts/plotly";
import { getAPIEndpoint } from "../../endpoint";
import { useSearchParams } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { getArctanderDescription } from "../../utils";

export const SearchPanelCompoundCard: FC<{compound: Compound, isOpened?: boolean}> = (props: {compound: Compound, isOpened?: boolean}) => {
    var { compound, isOpened } = props;
    const [ searchParams, setSearchParams ] = useSearchParams()
    
    if (isOpened === undefined) {
      isOpened = false
    }

    const [isModalVisible, setIsModalVisible] = useState(isOpened);
    const [fullCompound, setFullCompound] = useState<Compound | null>(null);
  
    useEffect(() => {
      if (isModalVisible) {
        fetch(`${getAPIEndpoint()}/compound?id=${compound.ID}`)
          .then(resp => resp.json())
          .then(data => setFullCompound(data))
      }
    }, [isModalVisible])

    const showModal = () => {
      searchParams.set("compoundID", compound.ID.toString())
      setSearchParams(searchParams)
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      searchParams.delete("compoundID")
      setSearchParams(searchParams)
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      searchParams.delete("compoundID")
      setSearchParams(searchParams)
      setIsModalVisible(false);
    };
  
    const handleA = () => {
      // dispatch(setA(compound))
      searchParams.set("compoundAID", compound.ID.toString())
      setSearchParams(searchParams)

      const key = `open${Date.now()}`;
      const btn = (<div>
        <Button style={{marginRight: '10px'}} type="primary" size="small" onClick={() => {
            notification.close(key)
            searchParams.delete("compoundID")
            setIsModalVisible(false)
          }}>
          <a href="#mixture">Mix!</a>  
        </Button>
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
          Close
        </Button>
      </div>
      );
      notification.open({
        message: 'Mixture compound A registered!',
        description:
          `${compound.CommonName} is registred as compound A`,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        btn,
        key,
      });
    }
  
    const handleB = () => {
      // dispatch(setB(compound))
      searchParams.set("compoundBID", compound.ID.toString())
      setSearchParams(searchParams)

      const key = `open${Date.now()}`;
      const btn = (<div>
        <Button style={{marginRight: '10px'}} type="primary" size="small" onClick={() => {
            notification.close(key)
            setIsModalVisible(false)
          }}>
          <a href="#mixture">Mix!</a>  
        </Button>
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
          Close
        </Button>
      </div>
      );

      notification.open({
        message: 'Mixture compound B registered!',
        description:
          `${compound.CommonName} is registred as compound B`,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        btn,
        key,
      });
    }

    let title = <div style={{
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "end",
      }}>
      <div style={{marginRight: '10px'}}>
        <div>
        <Typography.Text style={{ maxWidth: '150px'}} ellipsis={true}>
        {getArctanderDescription(compound) ? 
          <span style={{
            // fontSize: '14pt',
            // color: 'brown',
            marginRight: '3px',
          }}
        >
          <FontAwesomeIcon icon={faBook} />
        </span>
        : null}
          <b>{compound.CommonName}</b>
          </Typography.Text>
        </div>
        <div style={{fontSize: '9pt'}}>
          CAS: <a target="_blank" href={`https://commonchemistry.cas.org/detail?ref=${compound.CAS}`}>{compound.CAS} <ExportOutlined /></a>
        </div>
        
      </div>
      <div style={{fontSize: '9pt'}}>
        <div><a target="_blank" href={`http://www.thegoodscentscompany.com/data/${compound.TGSCID}.html`}>TGSC <ExportOutlined /></a></div>
        <div>{compound.PubchemCID? <a target="_blank" href={`https://pubchem.ncbi.nlm.nih.gov/compound/${compound.PubchemCID}`}>PubChem <ExportOutlined /></a> : null}</div>
        <div></div>
      </div>
      </div>
  
    return (
      <Col style={{minWidth: '300px', maxWidth: '300px'}}>
          <Card
            title={title}
            hoverable
            onClick={() => showModal()}
          >
            <Row justify='center'>
                {
                  compound.MeasuredSignatures !== null && compound.MeasuredSignatures.length > 0 ?
                    <SignaturesPlot compound={compound}/> : 
                    compound.ModeledSignature !== null ? <ModeledSignaturePlot compound={compound}/> : null
                }
            </Row>
            <Row justify="center">
              <Tag
                color={odorTypeColorMap[compound.OdorType.Name]}
                style={{color: "black", fontSize: '14pt', height: '18pt'}}
              >{compound.OdorType.Name}</Tag>
            </Row>
          </Card>
          <Modal
            title={title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='80vw'
            footer={[
                <Button key="submit" type="default" onClick={handleA}>
                  Mix as "A"
                </Button>,
                <Button key="submit" type="default" onClick={handleB}>
                  Mix as "B"
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                  Ok
                </Button>
              ]}
          >
            {fullCompound === null ? <Skeleton active /> : <CompoundPanel compound={fullCompound as Compound}/>}
          </Modal>
        </Col>
    )
  }
import { FC, useEffect, useState } from 'react';
import { Row, Col, Tag, Card, Modal, Button, Slider } from 'antd';

import Title from 'antd/lib/typography/Title';

import { useAppSelector } from '../../app/hooks';
import { Compound } from '../../types';

import {
  selectCompoundA,
  selectCompoundB
} from '../../features/mixer/mixerSlice';
import { ExperimentOutlined } from '@ant-design/icons';
import { chemicalFamiliesColorMap, odorStrengthColorMap, odorTypeColorMap } from '../../colormap/colormap';
import { SignatureResult } from '../search/signature/signature';
import { getAPIEndpoint } from '../../endpoint';
import { useSearchParams } from 'react-router-dom';
import { Column } from '@antv/g2plot';

const MixtureComponentCard: FC<{compound: Compound}> = (props: {compound: Compound}) => {
  let compound = props.compound
  let tagStyle = {color: 'black', marginBottom: '5px'}
  return (
    <Card
      style={{width: '100%'}}
      title={<div><ExperimentOutlined /> {compound.CommonName}</div>}
    >
      <Row justify='center' gutter={[20,20]}>
        <Tag style={tagStyle} color={odorStrengthColorMap[compound.OdorStrength.Name]}>{compound.OdorStrength.Name}</Tag>
        <Tag style={tagStyle} color={odorTypeColorMap[compound.OdorType.Name]}>{compound.OdorType.Name}</Tag>
      </Row>
      <Row justify='center' gutter={[20,20]}>
        {compound.ChemicalFamilies.map(cf => {console.log(cf); return (
          <Tag style={tagStyle} color={chemicalFamiliesColorMap[cf.Name]}>{cf.Name}</Tag>
        )})}
      </Row>
      {compound.PrimaryVaporPressure !== null && compound.PrimaryVaporPressure.split(" ").length > 0 ? 
        <Row justify='center' gutter={[20,20]}>
          <span><b>Volatility: </b>{parseFloat(compound.PrimaryVaporPressure.split(" ")[0]).toFixed(2)} mmHg</span>
        </Row> : null
      }
    </Card>
  )
}

const MixtureModalContent: FC<{compoundA: Compound, compoundB: Compound}> = (props: {compoundA: Compound, compoundB: Compound}) => {
  const { compoundA, compoundB } = props;

  const [ratio, setRatio] = useState<number>(50)
  const [shouldUpdateCounter, setShouldUpdateCounter] = useState<number>(0)
  const [mixedCompound, setMixedCompound] = useState<Compound | null>(null)
  

  useEffect(() => {
    fetch(`${getAPIEndpoint()}/compound/from/mixture?ratio=${ratio}&compoundAID=${compoundA.ID}&compoundBID=${compoundB.ID}`)
    .then(resp => {
      if (resp.ok) {
        return resp.json()
      } else {
        throw new Error()
      }
    })
    .then(data => setMixedCompound(data))
  }, [shouldUpdateCounter])

  return <div>
    <Row justify='center' gutter={[20,20]}>
      {[compoundA, compoundB].map(compound => {
        return compound !== null ?
        <Col>
          <MixtureComponentCard compound={compound}/>
        </Col>
        : null
      })}
    </Row>
    <Row justify='center'>
      <Slider
        value={ratio}
        onAfterChange={(value:number) => setShouldUpdateCounter(shouldUpdateCounter+1)}
        onChange={(value:number) => setRatio(value)}
        style={{width: '100%'}}
      />
    </Row>
    <Row justify='center'>
    {mixedCompound !== null ? <SignatureResult
        compound={mixedCompound}
    /> : null }
    </Row>
  </div>
}

export const MixturePanel: FC = () => {
  // const compoundA = useAppSelector(selectCompoundA)
  // const compoundB = useAppSelector(selectCompoundB)

  const [ compoundA, setCompoundA ] = useState<Compound | null>(null)
  const [ compoundB, setCompoundB ] = useState<Compound | null>(null)

  const [searchParams, setSearchParams ] = useSearchParams()

  var compoundAID: number = -1
  var componentAIDStr = searchParams.get('compoundAID')
  if (componentAIDStr === "") {
    componentAIDStr = null
  }
  if (componentAIDStr !== null) {
    compoundAID = parseInt(componentAIDStr)
  }

  var compoundBID: number = -1
  var componentBIDStr = searchParams.get('compoundBID')
  if (componentBIDStr === "") {
    componentBIDStr = null
  }
  if (componentBIDStr !== null) {
    compoundBID = parseInt(componentBIDStr)
  }

  var mixtureOpen: boolean = false
  var mixtureOpenStr = searchParams.get('mixtureOpen')
  if (mixtureOpenStr === "") {
    mixtureOpenStr = null
  }
  if (mixtureOpenStr !== null) {
    mixtureOpen = mixtureOpenStr === "0" ? false : true
  }
  
  var modalVisibleDefaultValue = false
  if (compoundAID > 0 && compoundBID > 0 && mixtureOpen) {
    modalVisibleDefaultValue = true
  }

  const [isModalVisible, setIsModalVisible] = useState(modalVisibleDefaultValue);

  useEffect(() => {
    if (compoundAID !== -1) {
      fetch(`${getAPIEndpoint()}/compound?id=${compoundAID}`)
        .then(resp => resp.json())
        .then(data => setCompoundA(data))
    }
  }, [compoundAID])

  useEffect(() => {
    if (compoundBID !== -1) {
      fetch(`${getAPIEndpoint()}/compound?id=${compoundBID}`)
      .then(resp => resp.json())
      .then(data => setCompoundB(data))
    }
  }, [compoundBID])

  const handleExit = () => {
    searchParams.delete("mixtureOpen")
    setSearchParams(searchParams)
    setIsModalVisible(false)
  }

  const handleClick = () => {
    searchParams.set("mixtureOpen", "1")
    setSearchParams(searchParams)
    setIsModalVisible(true)
  }

  const disabled = compoundA === null || compoundB === null

  return <div id='mixture'>
    <Row justify='center' gutter={[20, 20]} style={{flexDirection: 'column'}}>
      <Col >
        <Row justify='center'>
          <Col>
            <Title level={3}>Mixture Model</Title>
            <p>Aryballe's models can predict an odor of virtually any pair of mixed odorants</p>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row justify='center' gutter={[20, 20]}>
          
            {disabled ?
              <p>Please select both A and B components of the mixture</p>
              :
              [compoundA, compoundB].map(compound => {
                return compound !== null ?
                  <Col>
                    <MixtureComponentCard compound={compound}/>
                  </Col> : null
              })
            }
          
        </Row>
      </Col>
      {/* <Col>
        <Row justify='center' gutter={[20, 20]}>
          {}
        </Row>
      </Col> */}
      <Col >
      <Row justify='center'>
        <Col>
          <Button
              disabled={disabled}
              type="primary"
              onClick={handleClick}
            >
              Generate Mixture
            </Button>
            <Modal
              title='Predicted mixture characteristics'
              visible={isModalVisible}
              onOk={handleExit}
              onCancel={handleExit}
              width='80vw'
            >
              {compoundA !== null && compoundB !== null ? <MixtureModalContent
                compoundA={compoundA}
                compoundB={compoundB}
              /> : null }
            </Modal>
        </Col>
      </Row>
      </Col>
    </Row>
  </div>
}
import { Col, Collapse, Divider, Row, Tag } from "antd";
import { FC } from "react";
import { chemicalFamiliesColorMap, odorTypeColorMap } from "../../colormap/colormap";
import { Compound, Smell } from "../../types";
import { getArctanderDescription, getEffectiveStrengthPercentage } from "../../utils";
import { GaugePlot } from "../charts/antv";
import { WordCloud } from '@ant-design/plots'
import { ChemicalFamiliesPlot, ModeledSignaturePlot, SignaturesPlot } from "../charts/plotly";
import Text from "antd/lib/typography/Text";

export const CompoundPanel: FC<{compound: Compound}> = (props: {compound: Compound}) => {
    let compound = props.compound

    let arctanderDescription: string = getArctanderDescription(compound)

    return (
      <div>
        {arctanderDescription !== "" ?
          <Row justify='center' gutter={[20,20]}>
            <Col>
              <blockquote style={{textAlign: 'right', paddingLeft: '20%'}}>
                <div style={{fontSize: '10pt'}}>{arctanderDescription}</div>
                <cite style={{fontSize: '16pt'}}>— Steffen Arctander</cite>
              </blockquote>
            </Col>
          </Row>
        : null}
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <Divider>Nomenclature</Divider>
            <div>
              <div><b>CAS:</b> {compound.CAS}</div>
              <div><b>Common Name:</b> {compound.CommonName}</div>
              <div><b>IUPAC Name:</b> {compound.IUPACName}</div>
              <div><b>SMILES:</b> {compound.Smiles}</div>
              <div><b>Empirical Formula:</b> {compound.EmpiricalFormula}</div>
              {compound.ChemicalFamilies != null ? <div><b>Chemical Families: </b>{compound.ChemicalFamilies.map((cf, i) => {
                return <span><Tag style={{color: "black"}} color={chemicalFamiliesColorMap[cf.Name]}>{cf.Name}</Tag></span>
              })}</div>: null }
              {compound.PubchemCID ? <div><img style={{height: '100px'}} src={`https://pubchem.ncbi.nlm.nih.gov/image/img3d.cgi?&cid=${compound.PubchemCID}&t=l`}></img></div> : null }
            </div>

          </Col>
          <Col xs={24} md={12}>
            <Divider >Known odor percepts</Divider>
              <div>
                <WordCloud
                  data={compound.Smells.map(s => ({
                    type: s.Name,
                    weight: 1/s.Level
                  }))}
                  random={0.5}
                  height={200}
                  wordField='type'
                  weightField='weight'
                  interactions={[
                    {
                      type: 'element-active',
                    },
                  ]}
                  colorField='type'
                  // color='#122c6a'
                  color={(datum) => {
                    let color = datum.color
                    // console.log(datum)
                    let customColor = odorTypeColorMap[color]
                    return customColor ? customColor : '#122c6a'
                  }}
                  state={{
                    active: {
                      style: {
                        lineWidth: 2,
                      },
                    }
                  }}
                ></WordCloud>
              </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
        <Col xs={24} md={12}>
          <Collapse>
            <Collapse.Panel
              header="Physical Properties"
              key={1}
            >
                {compound.VaporDensities != null && compound.VaporPressures.length > 0 ? <div><b>Vapor pressure:</b>
                  {compound.VaporPressures.map(e => <div style={{marginLeft: '10px'}}> • {e.Name}</div>)}
                </div> : null}

                {compound.VaporDensities != null && compound.VaporDensities.length > 0 ? <div><b>Vapor Density:</b>
                  {compound.VaporDensities.map(e => <div style={{marginLeft: '10px'}}> • {e.Name}</div>)}
                </div> : null}

                {compound.MeltingPoints != null && compound.MeltingPoints.length > 0 ? <div><b>Melting Point:</b>
                  {compound.MeltingPoints.map(e => <div style={{marginLeft: '10px'}}> • {e.Name.replaceAll('.', '')}</div>)}
                </div> : null}
                {compound.BoilingPoints != null &&  compound.BoilingPoints.length > 0 ? <div><b>Boiling Point:</b>
                  {compound.BoilingPoints.map(e => <div style={{marginLeft: '10px'}}> • {e.Name.replaceAll('.', '')}</div>)}
                </div> : null }
                {compound.FlashPoints != null &&  compound.FlashPoints.length > 0 ? <div><b>Flash Point:</b>
                  {compound.FlashPoints.map(e => <div style={{marginLeft: '10px'}}> • {e.Name.replaceAll('.', '')}</div>)}
                </div> : null }
                {compound.SpecificGravities != null && compound.SpecificGravities.length > 0 ? <div><b>Specific Gravity:</b>
                  {compound.SpecificGravities.map(e => <div style={{marginLeft: '10px'}}> • {e.Name.replaceAll('.', '')}</div>)}
                </div>: null }

                {compound.SolubleIn != null && compound.SolubleIn.length > 0 ? <div><b>Solubile in:</b>
                  {compound.SolubleIn.map(e => <div style={{marginLeft: '10px'}}> • {e.Name.replaceAll('.', '')}</div>)}
                </div> : null }
                {compound.InsolubleIn != null && compound.InsolubleIn.length > 0 ? <div><b>Insolubile in:</b>
                  {compound.InsolubleIn.map(e => <div style={{marginLeft: '10px'}}> • {e.Name.replaceAll('.', '')}</div>)}
                </div> : null }
            </Collapse.Panel>
            <Collapse.Panel
              header="Known odor percepts​"
              key={2}
            >
              <div>{compound.Smells.map((smell: Smell, i: number) => <div >{smell.Level} ({(100/smell.Level).toFixed(0)}%): <Tag style={{color: "black"}} color={odorTypeColorMap[smell.Name]}>{smell.Name}</Tag></div>)}</div>
            </Collapse.Panel>
            {compound.AlternativeOdorDescriptions != null ? <Collapse.Panel
              header="Supplier descriptions"
              key={3}
            >
              <div>{compound.AlternativeOdorDescriptions.map(aod => {
                return <span><b>{aod.Supplier.Name} ({aod.ProductName})</b>: {aod.Description}<br/></span>
              })}</div>
            </Collapse.Panel> : null}
          </Collapse>
          </Col>
          <Col xs={24} md={12}>
            <Collapse>
              {compound.AryballeSamplePreparation.Solvent || compound.AryballeSamplePreparation.Dilution || compound.AryballeSamplePreparation.WaterInjection ? <Collapse.Panel
                header="Sample Preparation"
                key={1}
              >
                <div>
                {compound.AryballeSamplePreparation.Solvent !== null ? <div><b>Solvent:</b> {compound.AryballeSamplePreparation.Solvent}</div> : null }
                {compound.AryballeSamplePreparation.Dilution !== null ? <div><b>Dilution @ 10 rad:</b> {compound.AryballeSamplePreparation.Dilution}</div>: null }
                {compound.AryballeSamplePreparation.WaterInjection !== null ? <div><b>Water Injection, ul/ml:</b> {compound.AryballeSamplePreparation.WaterInjection}</div>: null }
              </div>
              </Collapse.Panel> : null}
              <Collapse.Panel
                header="Synonyms"
                key={2}
              >
                {compound.Synonyms.map(s => <div>{s.Name}</div>)}
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
        <Row justify='center' gutter={[20, 20]}>
          <Col xs={24} md={12}>
            {compound.PredictedChemicalFamilies !== null ? <Divider>Predicted Chemical Families</Divider> : null }
            {compound.PredictedChemicalFamilies !== null ? compound.PredictedChemicalFamilies.map(cf => {
              // console.log(cf)
              return <ChemicalFamiliesPlot chemicalFamilies={cf}/>
            }) : null
            }
          </Col>
        </Row>
        {/* <Row justify='center' gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <Divider>Odor Strength</Divider>
              <GaugePlot percent={getEffectiveStrengthPercentage(compound)}/>
          </Col>
        </Row> */}
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <Divider>Modeled signature</Divider>
            {compound.ModeledSignature !== null && compound.ModeledSignature.NormalizedSignature !== null? <ModeledSignaturePlot compound={compound}/> : null }
            {compound.ModeledSignature !== null && compound.ModeledSignature.EncodedSignature !== null ?
              <p><b>B-Code ({compound.ModeledSignature.Type.Name})</b> <Text copyable={true}>{compound.ModeledSignature.EncodedSignature}</Text></p> : null
            }
          </Col>
          <Col xs={24} md={12}>
            {compound.MeasuredSignatures !== null ? <Divider>Measured signatures</Divider> : null }
            {compound.MeasuredSignatures !== null ? <SignaturesPlot compound={compound}/> : null }
            {compound.AggregatedSignatures !== null ? 
              compound.AggregatedSignatures.map(aggSig => <p><b>B-Code ({aggSig.Type.Name}): </b> <Text copyable={true}>{aggSig.EncodedSignature}</Text></p>)
            : null }
          </Col>
        </Row>
      </div>
    )
  }
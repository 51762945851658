import { RightOutlined } from "@ant-design/icons";
import { CirclePacking } from "@ant-design/plots";
import { Col, Divider, Row, Tabs } from "antd";
import Search from "antd/lib/input/Search";
import { FC, useEffect, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { odorStrengthColorMap, odorTypeColorMap } from "../../../colormap/colormap";
import { getAPIEndpoint } from "../../../endpoint";
import { ChemicalFamily, Compound, PredictedChemicalFamily, Smell, SmellStat } from "../../../types";
import { getContrast } from "../../../utils";
import { CommonPropertiesBarPlot } from "../../charts/antv";
import { ChemicalFamiliesPlot, SignaturesPlot } from "../../charts/plotly";
import { CompoundPanel } from "../../compound/compound";
import { SearchPanelCompoundCard } from "../../compound/compoundCard";

export const ChemicalFamiliesCombinedInsight: FC<{chemicalFamilies: ChemicalFamily[], weights: number[]}> = (props: {chemicalFamilies: ChemicalFamily[], weights: number[]}) => {
  const { chemicalFamilies, weights } = props;
  const [ smellStats, setSmellStats ] = useState<SmellStat[]>([]);

  useEffect(() => {
    fetch(`${getAPIEndpoint()}/smells/by/chemical_families?ids=${chemicalFamilies.map(e => e.ID).join(",")}&weights=${weights.map(w => w.toFixed(3)).join(",")}`)
    .then(resp => {
      if (resp.ok) {
        return resp.json()
      } else {
        throw new Error()
      }
    })
    .then(data => setSmellStats(data))
  }, [chemicalFamilies, weights])
  
  const valueSum = smellStats.reduce((acc, cur) => acc+=cur.WeightedRelativeSumOfLevels, 0)
  const statsKeyValues = smellStats.map(s => {
    return {
      name: s.Smell.Name,
      value: Math.round((s.WeightedRelativeSumOfLevels/valueSum)*100)
    }
  })

  
  const data = {
    name: 'root',
    children: statsKeyValues.filter(e => e.value >= 1)
  }

  // console.log(data)

  return <div
      style={{height: '350px', width: '100%'}}
    >
    <CirclePacking
      autoFit={true}
      data={data}
      renderer='svg'
      label={{
        formatter: ({ name }) => {
          return name !== 'root' ? name : ''
        },
        offsetY: 8,
        style: {
          fontSize: 18,
          stroke: 'white',
          lineWidth: 1.5,
          textAlign: 'center',
          mixBlendMode: 'difference'
          // fill: 'rgba(0,0,0,0.65)',
        }
      }}
      color={(datum) => {
        // console.log({datum})
        if (datum.name === 'root') {
          return 'transparent'
        } else {
          let color = odorTypeColorMap[datum.name]
          if (color !== undefined) {
            return color
          } else {
            return '#ccc' //'rgba(0,0,0,0)-rgba(0,0,0,0)'
          }
        }
      }}
      legend={false}
    />
    </div>
}

const ChemicalFamilyInsight: FC<{chemicalFamily: ChemicalFamily, ratio: number}> = (props: {chemicalFamily: ChemicalFamily, ratio: number}) => {
  const { chemicalFamily, ratio } = props;
  const [smellStats, setSmellStats] = useState<SmellStat[]>([]);
  useEffect(() => {
    fetch(`${getAPIEndpoint()}/smells/by/chemical_family?q=${chemicalFamily.ID}`)
    .then(resp => resp.json())
    .then(data => setSmellStats(data))
  }, [chemicalFamily])

  let statKeyValue = smellStats.map(s => ({
    key: s.Smell.Name,
    value: s.RelativeSumOfLevels
  }))

  let height = `${Math.round(ratio*200)}px`

  return <div
    style={{height: height}}
  >
    <CommonPropertiesBarPlot properties={statKeyValue} colormap={odorTypeColorMap}/>
  </div>
}

export const SignatureResult: FC<{compound: Compound}> = (props: {compound: Compound}) => {
    const { compound } = props
    if (compound.PredictedChemicalFamilies == null) {
      return null
    }
    const predictedChemicalFamily = Object.values(compound.PredictedChemicalFamilies)[0]
    const probabilitiesSum = predictedChemicalFamily.Probabilities.reduce((cur, acc) => acc+cur, 0)

    return <div style={{width: '100%'}}>
      <Row justify='center' align="middle" gutter={[20,20]}>
        <Col xs={24} md={7}>
          <Divider>Signature</Divider>
          {compound.MeasuredSignatures !== null ? <SignaturesPlot compound={compound}/> : null }
        </Col>
        <Col span={1}>
          <RightOutlined style={{fontSize: '18pt'}}/>
        </Col>
        <Col xs={24} md={7}>
          <Divider >Chemical Families</Divider>
            {predictedChemicalFamily !== null ? <ChemicalFamiliesPlot chemicalFamilies={predictedChemicalFamily}/> : null }
        </Col>
        <Col span={1}>
          <RightOutlined style={{fontSize: '18pt'}}/>
        </Col>
        <Col xs={24} md={7}>
          <Divider>Odor percepts</Divider>
            <ChemicalFamiliesCombinedInsight
              chemicalFamilies={predictedChemicalFamily.Targets}
              weights={predictedChemicalFamily.Probabilities.map(p => p/probabilitiesSum)}
            />
        </Col>
      </Row>
      {/* <Row justify='center' gutter={[20,20]}>
          <Col xs={24} md={12}>
            <Divider>First order perceptions: {Math.round(100*predictedChemicalFamily.Probabilities[0]/probabilitiesSum)}%</Divider>
            <ChemicalFamilyInsight
              chemicalFamily={predictedChemicalFamily.Targets[0]}
              ratio={predictedChemicalFamily.Probabilities[0]/probabilitiesSum}
            />
          </Col>
          <Col xs={24} md={12}>
            <Divider>Second order perceptions: {Math.round(100*predictedChemicalFamily.Probabilities[1]/probabilitiesSum)}%</Divider>
            <ChemicalFamilyInsight
              chemicalFamily={predictedChemicalFamily.Targets[1]}
              ratio={predictedChemicalFamily.Probabilities[1]/probabilitiesSum}
            />
          </Col>
      </Row> */}
    </div>
  }

export const SearchPanelSignatureRecognitionResult: FC<{searchValue: string}> = (props: {searchValue: string}) => {
    const [compound, setCompound] = useState<Compound | null>(null)
    const { searchValue } = props;
    useEffect(() => {
      fetch(`${getAPIEndpoint()}/chemical_families/by/signature?q=${encodeURIComponent(searchValue)}`)
        .then(resp => {
          if (resp.ok) {
            return resp.json()
          } else {
            throw new Error()
          }
        })
        .then(data => setCompound(data))
    }, [searchValue])
    if (compound === null) {
      return null
    }

    return (
      <SignatureResult
          compound={compound}
      />
    )
  }
  
export const SearchPanelSignatureMatchingResult: FC<{searchValue: string}> = (props: {searchValue: string}) => {
  const [compounds, setCompound] = useState<Compound[]>([])
  const { searchValue } = props;
  const n = 5
  useEffect(() => {
    fetch(`${getAPIEndpoint()}/compounds/by/signature?q=${encodeURIComponent(searchValue)}&n=${n}`)
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        } else {
          throw new Error()
        }
      })
      .then(data => setCompound(data))
  }, [searchValue])
  if (compounds.length === 0) {
    return null
  }
  console.log(compounds)
  return (
    <Row justify='center' gutter={[20, 20]}>
      <Divider>Best matching VOCs</Divider>
      {compounds.map(compound => 
          <SearchPanelCompoundCard compound={compound}/>
      )}
    </Row>
  )
}

export const SearchPanelInputSignature: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  var searchSignature: string = ""
  var searchSignatureStr = searchParams.get('searchSignature')
  if (searchSignatureStr === "") {
    searchSignatureStr = null
  }
  if (searchSignatureStr !== null) {
    searchSignature = searchSignatureStr
  }

  const handleSearch = (value: string) => {
    searchParams.set("searchSignature", value)
    setSearchParams(searchParams)
  }

  return (
      <Col span={24}>
        <Row justify='center'>
          <Search
            addonBefore="B-Code"
            placeholder="Paste the B-Code here.."
            allowClear
            onSearch={handleSearch}
            style={{ width: '100%'}}
            size='large'
            defaultValue={searchSignature}
            autoFocus={true}
        />
        </Row>
        <Row justify='center'>
          <Col span={18}>
            <SearchPanelSignatureRecognitionResult
              searchValue={searchSignature}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SearchPanelSignatureMatchingResult
              searchValue={searchSignature}
            />
          </Col>
        </Row>
      </Col>
    )
  }
  
import { Data } from 'plotly.js';
import { FC, useState } from 'react';
import Plot from 'react-plotly.js';
import { chemicalFamiliesColorMap } from '../../colormap/colormap';
import { AggregatedSignature, Compound, PredictedChemicalFamily } from '../../types';

const closeArray = (a: any[]) => {
    return a.concat(a.slice(0, 1))
}
  
const min_max_norm = (row: number[]): number[] => {
    var min = Math.min(...row)
    var max = Math.max(...row)
    var numerator = row.map(cur => cur-min)
    var denominator = max - min
    return numerator.map(cur => cur/denominator)
}

const defaultPlotlyArguments = {
    layout: {
      autosize: true,
      showlegend: false,
      margin: {
        t: 50,
        b: 50,
        l: 10,
        r: 10,
      },
      angularaxis: {
        showticklables: false,
        fixedrange: true
      },
      radialaxis: {
        showticklables: false,
        fixedrange: true
      },
    },
    config: {
      displaylogo: false,
      staticPlot: true
    },
    style: {
      width: "100%",
      height: "350px"
    }
  }

export const SignaturesPlot: FC<{compound: Compound, types?: number[]}> = (props: {compound: Compound, types?: number[]}) => {
    const { compound, types } = props;
    let signatures;
    if (types !== undefined) {
      signatures = compound.MeasuredSignatures.filter((sig) => types.includes(sig.Type.ID))
    } else {
      signatures = compound.MeasuredSignatures
    }
    return <Plot
      data={signatures.map(sig => ({
        name: sig.ID.toString(),
        type: "scatterpolar",
        theta: closeArray(sig.Type.Peptides.map(p => 'P'+p)),
        r: closeArray(min_max_norm(sig.NormalizedSignature)),
        fill: function () {
          switch (sig.Type.ID) {
            case 0:
              return 'none'
            case 1:
              return 'none'
            case -100:
              return 'none'
            default:
              return 'toself'
          }
        }(),
        line: {
          color: function () {
            switch (sig.Type.ID) {
              case 0:
                return '#7451F1' // 'red'
              case 1:
                return '#7451F1'  // green
              case -100:
                return '' //'#1193F5'
              default:
                return 'purple'
            }
          }()
        }
      }))}
      useResizeHandler={true}
      layout={defaultPlotlyArguments.layout}
      config={defaultPlotlyArguments.config}
      style={defaultPlotlyArguments.style}
    />
}

export const AggregatedSignaturePlot: FC<{color: string, aggregatedSignature: AggregatedSignature}> = (props: {color: string, aggregatedSignature: AggregatedSignature}) => {
  const { color, aggregatedSignature } = props;
  return <Plot
    data={[{
      line: {color: color},
      type: "scatterpolar",
      theta: closeArray(aggregatedSignature.Peptides.map(p => 'P'+p)),
      r: closeArray(min_max_norm(aggregatedSignature.SignatureMedian)),
    },
    {
      line: {color: color},
      type: "scatterpolar",
      theta: closeArray(aggregatedSignature.Peptides.map(p => 'P'+p)),
      r: closeArray(min_max_norm(aggregatedSignature.SignatureMedian.map((e, i) => e-aggregatedSignature.SignatureErr[i]))),
      
    },
    {
      line: {color: color},
      type: "scatterpolar",
      theta: closeArray(aggregatedSignature.Peptides.map(p => 'P'+p)),
      r: closeArray(min_max_norm(aggregatedSignature.SignatureMedian.map((e, i) => e+aggregatedSignature.SignatureErr[i]))),
      fill: "tonext"
    }
  ]}
    useResizeHandler={true}
    layout={defaultPlotlyArguments.layout}
    config={defaultPlotlyArguments.config}
    style={defaultPlotlyArguments.style}
  />
}

export const MultiCompoundSignaturesPlot: FC<{compounds: Compound[]}> = (props: {compounds: Compound[]}) => {
  const { compounds } = props;
  // let signatures: Signature[] = []
  let _includedCompounds = new Set(compounds.map(c => c.ID))
  const [ includedCompounds, setIncludedCompounds ] = useState<Set<number>>(_includedCompounds)
  let curveNumberToCompound: Record<number, number> = {}

  console.log({includedCompounds, _includedCompounds})

  let data: Data[] = []
  compounds.forEach((c, i) => {
    curveNumberToCompound[i] = c.ID
    c.AggregatedSignatures.forEach(sig => {
      data.push({
        name: `${c.CommonName}:${c.ID}`,
        // legendgroup: c.CommonName,
        type: "scatterpolar",
        theta: closeArray(sig.Type.Peptides.map(p => 'P'+p)),
        r: closeArray(min_max_norm(sig.NormalizedSignature)),
        fill: function () {
          switch (sig.Type.ID) {
            case 0:
              return 'none'
            case 1:
              return 'none'
            default:
              return 'toself'
          }
        }(),
        line: {
          color: function () {
            switch (sig.Type.ID) {
              case 0:
                return '#7451F1' // 'red'
              case 1:
                return '#7451F1'  // green
              default:
                return '#1193F5' //'purple'
            }
          }()
        }
      })
    })
  })
  return <div>
    <Plot
      data={data}
      useResizeHandler={true}
      layout={{...defaultPlotlyArguments.layout, ...{showlegend: true}}}
      config={defaultPlotlyArguments.config}
      style={defaultPlotlyArguments.style}
      onClick={(e) => {
        console.log('clicked', e)
      }}
      // onLegendClick={(e) => {
      //   let c = curveNumberToCompound[e.curveNumber]
      //   console.log('legend clicked', e.curveNumber, c)
      //   if (includedCompounds.has(c)) {
      //     includedCompounds.delete(c)
      //   } else {
      //     includedCompounds.add(c)
      //   }
      //   setIncludedCompounds(new Set(includedCompounds))
      //   return includedCompounds.has(c)
      // }}
    />
    <div>{Array.from(includedCompounds).join(', ')}</div>
  </div>
}

export const ChemicalFamiliesPlot: FC<{chemicalFamilies: PredictedChemicalFamily }> = (props: {chemicalFamilies: PredictedChemicalFamily}) => {
    const { chemicalFamilies } = props;
    return <Plot
    data={[{
      type: 'pie',
      values: chemicalFamilies.Probabilities,
      labels: chemicalFamilies.Targets.map(t => t.Name),
      textinfo: "label",
      insidetextorientation: "radial",
      automargin: false,
      marker: {
        colors: chemicalFamilies.Targets.map(t => chemicalFamiliesColorMap[t.Name]),
      }
    }]}
    useResizeHandler={true}
    layout={defaultPlotlyArguments.layout}
    config={defaultPlotlyArguments.config}
    style={defaultPlotlyArguments.style}
  />
}

export const ModeledSignaturePlot: FC<{compound: Compound}> = (props: {compound: Compound}) => {
  const { compound } = props;
  return <Plot
  data={[{
    type: "scatterpolar",
    theta: closeArray(compound.ModeledSignature.Type.Peptides.map(e=>'P'+e)),
    r: closeArray(min_max_norm(compound.ModeledSignature.NormalizedSignature)),
    fill: "toself",
    line: {
      color: '#1193F5'
    }
  }]}
  useResizeHandler={true}
  layout={defaultPlotlyArguments.layout}
  config={defaultPlotlyArguments.config}
  style={defaultPlotlyArguments.style}
/>
}
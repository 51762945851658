import { Col, Radio, RadioChangeEvent, Row } from "antd";
import { FC, useEffect, useState } from "react";

import { SearchType } from "../../types";
import { SearchPanelSelectOdor } from "./odor/odor";
import { SearchPanelInputSignature } from "./signature/signature";
import { SearchPanelSelectChemicalFamily } from "./chemicalFamily/chemicalFamily";

import { createSearchParams, useSearchParams } from "react-router-dom";
import { SearchPanelSelectVOC } from "./voc/voc";

export const SearchPanelSelect: FC<{searchType: SearchType}> = (props: {searchType: SearchType}) => {
    switch (+props.searchType) {
      case SearchType.Odor:
        return <SearchPanelSelectOdor/>
      case SearchType.ChemicalFamily:
          return <SearchPanelSelectChemicalFamily/>
          case SearchType.VOC:
            return <SearchPanelSelectVOC/>
      case SearchType.Signature:
        return <SearchPanelInputSignature/>
      default:
        return <SearchPanelSelectOdor/>
    }
  }

export const SearchPanel: FC = () => {
    // const [searchType, setSearchType] = useState<SearchType>(+SearchType.Odor);
    const [searchParams, setSearchParams] = useSearchParams();
  
    var searchType: SearchType = +SearchType.Odor
    const searchTypeStr = searchParams.get('searchType')
    if (searchTypeStr !== null) {
      searchType = parseInt(searchTypeStr)
    }

    const handleChange = (e: RadioChangeEvent) => {
      searchParams.set("searchType", e.target.value)
      setSearchParams(searchParams)
    }

    return (
      <Col span={24}>
        <Row justify='center' gutter={[20, 20]} style={{marginTop: '15px'}}>
            <Radio.Group
              size='large'
              onChange={handleChange}
              value={searchType}
            >
              <Radio value={+SearchType.Odor}>Odor Percepts</Radio>
              <Radio value={+SearchType.ChemicalFamily}>Chemical Family</Radio>
              <Radio value={+SearchType.VOC}>VOC</Radio>
              <Radio value={+SearchType.Signature}>Signature</Radio>
            </Radio.Group>
        </Row>      
        <Row justify='center' gutter={[20, 20]} style={{marginTop: '60px'}}>
          <Col span={24}>
            <SearchPanelSelect searchType={searchType}></SearchPanelSelect>
          </Col>
        </Row>
      </Col>
    )
  }
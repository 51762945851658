 
export interface KeyValuePair {
    key: string
    value: string | number
  }
  
export interface NamedEntity {
    ID:  number
    Name: string
}

export interface Smell extends NamedEntity {
    Level: number
}

export interface Source extends NamedEntity {}

export interface OdorType extends NamedEntity {}

export interface OdorStrength extends NamedEntity {}

export interface ChemicalFamily extends NamedEntity {}

export interface SignatureType extends NamedEntity {
    Peptides: number[]
}

export interface Signature {
    ID:                   number
    Type: SignatureType
    RawSignature: number[]
    NormalizedSignature: number[]
    EncodedSignature: string
}

export interface PredictedChemicalFamily {
    SignatureType: SignatureType,
    Probabilities: number[]
    Targets: ChemicalFamily[]
}

export interface AryballeSamplePreparation {
    ID:             number
    Solvent:        string
    Dilution:       string
    WaterInjection: string
}

export interface Supplier extends NamedEntity {}

export interface PrimaryOdorDescription {
    ID:          number
	Supplier:    Supplier
	Condition:   string
	Reference:   string
	Description: string
}

export interface AlternativeOdorDescription {
    ID:          number
	Supplier:    Supplier
	ProductName:   string
	Description: string
}

export interface Compound {
    ID:             number
    TGSCID:         string

    CAS:             string
    PubchemCID:      number
    PubchemSID:      number

    CommonName:         string
    IUPACName:          string
    Smiles:             string
    EmpiricalFormula:   string

    Appearance:     string
	Storage:        string
	ShelfLife:      string
	Substantivity:  string

    OdorType: OdorType
    OdorStrength: OdorStrength

    MolecularWeight:        number
    LogP:                   string
    
    AryballeSamplePreparation: AryballeSamplePreparation
    PrimaryOdorDescription: PrimaryOdorDescription
    AlternativeOdorDescriptions: AlternativeOdorDescription[]
    
    Synonyms: NamedEntity[]
    MeltingPoints:    NamedEntity[]
    BoilingPoints:     NamedEntity[]
    FlashPoints: NamedEntity[]
    VaporPressures: NamedEntity[]
    VaporDensities: NamedEntity[]
    SpecificGravities: NamedEntity[]
    RefractiveIndices: NamedEntity[]
    SolubleIn: NamedEntity[]
    InsolubleIn: NamedEntity[]

    PrimaryVaporPressure:   string

    Smells:  Smell[]
    ChemicalFamilies: ChemicalFamily[]
    
    MeasuredSignatures: Signature[]
    AggregatedSignatures: Signature[]
    ModeledSignature: Signature

    PredictedChemicalFamilies: PredictedChemicalFamily[]

    Distance: number
}

export interface MatchedSmell {
    Smell: Smell
    CompoundIDs: number[]
    CompoundNames: string[]
}

export interface RecognitionResult {

}

export enum SearchType {
    Odor,
    VOC,
    ChemicalFamily,
    Signature
}

export interface SmellStat {
    Smell: Smell
    SumOfLevels: number
    RelativeSumOfLevels: number
    WeightedRelativeSumOfLevels: number
  }

export interface AggregatedSignature {
    SignatureMedian: number[]
    SignatureErr: number[]
    Peptides: number[]
}

export interface SynonymOfCompound {
    ID: number
    Name: string
    Compound: Compound
}
import { Col, Pagination, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { Compound } from "../../types";
import { SearchPanelCompoundCard } from "../compound/compoundCard";

export const PaginatedCompoundCardSet: FC<{compounds: Compound[]}> = (props: {compounds: Compound[]}) => {
    const { compounds } = props;
    const [ activeCompounds, setActiveCompounds ] = useState<Compound[]>([])
    const [ currentPage, setCurrentPage ] = useState<number>(1)

    useEffect(() => {
      setActiveCompounds(compounds.slice(0,10))
    }, [compounds])
  
    console.log('compounds length', compounds.length)
  
    const paginationConfig = {
      total: compounds.length,
      showTotal: (total: number) => `Total: ${total}`,
      defaultPageSize: 10,
      pageSizeOptions: [10, 20],
      defaultCurrent: 1,
      hideOnSinglePage: true,
      current: currentPage,
      onChange: (pageNumber: number, pageSize: number) => {
        console.log(pageNumber, pageSize)
        let _activeCompounds: Compound[] = []
        compounds.forEach((compound, i) => {
          if (Math.floor(i/pageSize) === pageNumber-1) {
            _activeCompounds.push(compound)
          }
        })
        console.log(_activeCompounds)
        setActiveCompounds(_activeCompounds)
        setCurrentPage(pageNumber)
      }
    }

    return <Col span={24}>
      <Row justify="center" gutter={[20,20]} style={{marginBottom: '10px'}}>
        <Col>
          <Pagination
            {...paginationConfig}
          />
        </Col>
      </Row>
      <Row justify="center" gutter={[20,20]}>
        {activeCompounds.map(compound => <SearchPanelCompoundCard compound={compound}/>)}
      </Row>
      <Row justify="center" gutter={[20,20]} style={{marginTop: '10px'}}>
        <Col>
          <Pagination
            {...paginationConfig}
          />
        </Col>
      </Row>
    </Col>
  }
import { Col, Row } from "antd"
import { chemicalFamiliesColorMap } from "../../colormap/colormap"
import { Card } from "antd";
import { FC, useEffect, useState } from "react";
import { getAPIEndpoint } from "../../endpoint";
import { AggregatedSignature } from "../../types";
import { AggregatedSignaturePlot } from "../charts/plotly";
import Title from "antd/lib/typography/Title";



export const AggregatedSignaturePanel: FC<{color: string, title: string, compounds: {ID: number}[]}> = (props: {color: string, title: string, compounds: {ID: number}[]}) => {
    const { color, title, compounds } = props;
    const [ aggregatedSignature, setAggregatedSignature ] = useState<AggregatedSignature | null>(null)

    useEffect(() => {
        fetch(`${getAPIEndpoint()}/aggregated/signature/by/compounds?q=${compounds.map(c => c.ID).join(",")}`)
        .then(resp => {
          if (resp.ok) {
            return resp.json()
          } else {
            throw new Error()
          }
        })
          .then(data => setAggregatedSignature(data))
      }, [compounds]
    )
    
    if (aggregatedSignature === null) {
      return null
    }

    return <div>
        <Card
            title={title}
        >
            <AggregatedSignaturePlot
                color={color}
                aggregatedSignature={aggregatedSignature}
            />
        </Card>
    </div>
}

const signatureShapes = [
    {
        title: 'Aliphatic Alcohols',
        color: chemicalFamiliesColorMap["Alcohol"],
        compounds: [
            854,
            879,
            1056,
            1114,
            1146,
            1177,
            1317,
            1363,
            1506,
            1522,
            1549,
            1818,
            2166,
            2211,
            2765,
            3503,
        ]
    },
    {
        title: 'Aromatic Alcohols',
        color: chemicalFamiliesColorMap["Alcohol"],
        compounds: [
            770,
            795,
            1168,
            1781,
            2988,
            2990,
            3143,
        ]
    },
    {
        title: 'Aliphatic Esters',
        color: chemicalFamiliesColorMap["Ester"],
        compounds: [
            749,
            896,
            1103,
            1178,
            1278,
            1502,
            1732,
        ]
    },
    {
        title: 'Aromatic Esters',
        color: chemicalFamiliesColorMap["Ester"],
        compounds: [
            791,
            1218
        ]
    },
    {
        title: 'Sulfurous',
        color: chemicalFamiliesColorMap["Sulfurous"],
        compounds: [
            1190,
            1191,
            3499,
        ]
    },
    {
        title: 'Aldehydes',
        color: chemicalFamiliesColorMap["Aldehyde"],
        compounds: [
            726,
            730,
            1160,
            1591,
            1643,
            1762,
            2097,
            2152,
            2163,
            2336,
            2337,
        ]
    },
    {
        title: 'Ketones',
        color: chemicalFamiliesColorMap["Ketone"],
        compounds: [
            773,
            855,
            1130,
            1194,
            1391,
        ]
    }
    

]

export const SignatureShapesPanel: FC = () => {
return <div id='shapes'>
    <Row justify='center' gutter={[20, 20]}>
        <Col>
            <Title level={3}>Foundations</Title>
            <p>
            Aryballe’s digital olfaction solutions <b>connect the chemical space to the odor perception space</b> with odor signatures captured by our proprietary sensors.
            <br></br>
            The <b>characteristics of these signature shapes</b> can be connected to chemical families.
            </p>
        </Col>
    </Row>
    <Row justify='center' gutter={[20, 20]}>
        {signatureShapes.map(config => {
            return <Col>
                <div style={{minWidth: '250px'}}>
                    <AggregatedSignaturePanel
                        color={config.color}
                        title={config.title}
                        compounds={config.compounds.map(e => ({ID: e}))}
                    />
                </div>
            </Col>
        })}
    </Row>
</div>
}